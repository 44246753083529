@media screen and (max-width: 768px) {
  .drawing {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0px;
    margin-bottom: 10px;
  }
  .drawing_list {
    width: 100%;
    height: 160px;
  }
  .li {
    margin-left: 10px;
  }
  .drawing_img {
    flex-basis: auto;
    height: 340px;
    width: 100%;
    min-width: 0px;
    margin-top: 20px;
  }
  .img {
    margin-bottom: 10px;
  }

  .adjust_W {
    width: 80%;
    max-height: 300px;
  }
  .adjust_S {
    height: 300px;
    width: 300px;
  }
  .adjust_H {
    height: 300px;
  }
}
@media screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
}
