.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.link {
  text-decoration: none;
  font-size: var(--font-ssmall);
  font-weight: var(--weight-semi-bold);
  font-family: var(--typo-eng);
  background-color: var(--color-blank);
  color: var(--color-pink);
  padding: 0px 12px;
}
