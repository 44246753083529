@media screen and (max-width: 768px) {
  .nav {
    margin-left: 0vw;
    margin-right: 2vw;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .link {
    padding: 0px 4px;
  }
  .hide {
    display: none;
  }
}
@media screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .nav {
    margin-left: 0vw;
    margin-right: 2vw;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .link {
    padding: 0px 4px;
  }
  .hide {
    display: none;
  }
}
